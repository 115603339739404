import React from "react";
import {Route, Switch} from "react-router-dom";

import asyncComponent from "../util/asyncComponent";

const App = ({match}) => (<div className="">
        <Switch>
            <Route path={`${match.url}/`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}home`} component={asyncComponent(() => import('./Home'))}/>
            <Route path={`${match.url}clients`} component={asyncComponent(() => import('./Clients'))}/>
            <Route path={`${match.url}client-details/:id`} component={asyncComponent(() => import('./Clients/ClientDetails'))}/>
            <Route path={`${match.url}client/start-setup`} component={asyncComponent(() => import('./CompanySetup'))}/>
            <Route path={`${match.url}client/manage-company/:slug`}
                   component={asyncComponent(() => import('./ManageCompany'))}/>
            <Route path={`${match.url}leads`} component={asyncComponent(() => import('./Leads'))}/>
            <Route path={`${match.url}lead-details/:id`} component={asyncComponent(() => import('./Leads/LeadDetails'))}/>
            <Route path={`${match.url}deals`} component={asyncComponent(() => import('./Deals'))}/>
            <Route path={`${match.url}contacts`} component={asyncComponent(() => import('./Contacts'))}/>
            <Route path={`${match.url}campaigns`} component={asyncComponent(() => import('./Campaigns'))}/>
            <Route path={`${match.url}tasks`} component={asyncComponent(() => import('./Tasks'))}/>
            <Route path={`${match.url}meetings`} component={asyncComponent(() => import('./Meetings'))}/>
            <Route path={`${match.url}bids`} component={asyncComponent(() => import('./Bids'))}/>
            <Route path={`${match.url}settings`} component={asyncComponent(() => import('./Settings'))}/>
            <Route path={`${match.url}sources`} component={asyncComponent(() => import('./Sources'))}/>
            <Route path={`${match.url}events`} component={asyncComponent(() => import('./Events'))}/>
        </Switch>
    </div>);

export default App;

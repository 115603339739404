import React, {useState} from "react";


import App from "../../routes/index";


import {Link, useRouteMatch} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Badge, Button, Dropdown, Layout, Menu, Modal, Space} from "antd";
import {footerText} from "../../util/config";


import {
    BellFilled,
    BellOutlined,
    CaretDownFilled,
    DownOutlined,
    HomeFilled, LogoutOutlined,
    SearchOutlined,
    SettingFilled, SettingOutlined, UserOutlined, EllipsisOutlined
} from "@ant-design/icons";
import CompanyDrawer from "../../components/CompanyDrawer";
import {openCompanyDrawer} from "../../appRedux/actions";
import {DOCUMENT_BASE_URL, MAIN_MENU} from "../../constants/ServerUrl";
import {NoPageAccess} from "../../components/NoPageAccess";
import GeneralSearch from "../../components/GeneralSearch";
import { min } from "moment";


const {Content, Header, Footer} = Layout;
const MainApp = () => {
    let [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const dispatch = useDispatch();
    const match = useRouteMatch();

    const {authUser} = useSelector(({auth}) => auth);
    let {pathname} = useSelector(({common}) => common);
    let selectedKeys = pathname.substr(1);
    let defaultOpenKeys = selectedKeys.split('/')[1];
    const currentUrl = window.location.hostname;
    const homeUrl = currentUrl.includes('dev') ? 'https://dev-home.phillipsoutsourcing.net/home' : 'https://pems.phillipsoutsourcing.net/home';
    
    const userMenu = (
        <Menu>

            <Menu.Item key="1">
               <Link to="/settings"> <Button  type="link" icon={<SettingOutlined />} size="small">
                    Settings
                </Button></Link>
            </Menu.Item>
            <Menu.Item key="2">
                <Badge dot>
                    <Button type="link" icon={<BellOutlined />} size="small">
                        Notifications
                    </Button>
                </Badge>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">
                <Button type="link" icon={<LogoutOutlined />} size="small" >
                    Logout
                </Button>
            </Menu.Item>
        </Menu>
    );

    

    const menuItems = [
        { key: 'clients', text: 'Clients', to: '/clients' },
        { key: 'contacts', text: 'Contacts', to: '/contacts' },
        { key: 'leads', text: 'Leads', to: '/leads' },
        { key: 'others', text: 'Others', to: '#' }, // Note the '#' as the to value
      ];
      
      const subMenuItems = [
        { key: 'campaigns', text: 'Campaigns', to: '/campaigns' },
        { key: 'deals', text: 'Deals', to: '/deals' },
        { key: 'tasks', text: 'Tasks', to: '/tasks' },
        { key: 'meetings', text: 'Meetings', to: '/meetings' },
        { key: 'bids', text: 'Bids', to: '/bids' },
        { key: 'events', text: 'Events', to: '/events' },
      ];

    //   const renderMenuItems = (items) => (
    //     items.map((item) => (
    //       <Menu.Item key={item.key} className="text-black">
    //         <Link to={item.to}>
    //           <span className="text-black">{item.text}</span>
    //         </Link>
    //       </Menu.Item>
    //     ))
    //   );

      const renderMenuItems = (items, isSubmenu = false) => (
        items.map((item) => (
          isSubmenu ? (
            <Menu.Item key={item.key} className="text-black">
              <Link to={item.to}>
                <span className="text-black">{item.text}</span>
              </Link>
            </Menu.Item>
          ) : (
            <Menu.Item key={item.key} className="text-black">
              <Link to={item.to}>
                <span className="text-black">{item.text}</span>
              </Link>
            </Menu.Item>
          )
        ))
      );

      const renderSubMenuItems = (items) => (
        items.map((item) => (
          <Menu.Item key={item.key} className="text-black">
            <Link to={item.to}>
              <span className="text-black">{item.text}</span>
            </Link>
          </Menu.Item>
        ))
    );

    return (
        <Layout className="">

                <Header className="bg-white p-0 h-16 flex justify-between">



                    <div className="flex justify-end">

                        <div className="m-3 ml-10 "
                            onClick={() => {
                                window.location.href = MAIN_MENU;
                            }}>

                            <img width="120" className="pb-4" alt="Phillips Outsourcing Logo" src="/images/logo.png"/>
                        </div>
                        
                        <Menu
                            className="text-base font-medium text-gray-500 "
                            mode="horizontal"
                            defaultOpenKeys={[defaultOpenKeys]}
                            selectedKeys={[selectedKeys]}
                            style={{width: '650px'}}

                        >

                            <Menu.Item key="logo" className="logo">

                            </Menu.Item>
                            <Menu.Item key="home-general" className="text-black">
                            <a href={window.location.hostname.includes('dev') ? 'https://dev-home.phillipsoutsourcing.net/home' : 'https://pems.phillipsoutsourcing.net/home'}  rel="noopener noreferrer">
                                <span className="text-black">Home</span>
                            </a>
                            </Menu.Item>
                            <Menu.Item key="home" className="text-black">
                                <Link to="/home" >
                                    <span className=" text-black" >Dashboard</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="clients" className="text-black">
                                <Link to="/clients" >
                                    <span className=" text-black" >Clients</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="contacts" className="text-black">
                                <Link to="/contacts" >
                                    <span className=" text-black" >Contacts</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="leads" className="text-black">
                                <Link to="/leads" >
                                    <span className=" text-black" >Leads</span>
                                </Link>
                            </Menu.Item>
                            {/* {subMenuItems.map((item) => (
                                <Menu.Item key={item.key} className="text-black">
                                    <Link to={item.to}>
                                        <span className="text-black">{item.text}</span>
                                    </Link>
                                </Menu.Item>
                            ))} */}

                            <Menu.SubMenu 
                                key="others" 
                                title={<EllipsisOutlined />} 
                                className="text-black"
                            >
                                {subMenuItems.map((item) => (
                                    <Menu.Item key={item.key} className="text-black">
                                        <Link to={item.to}>
                                            <span className="text-black">{item.text}</span>
                                        </Link>
                                    </Menu.Item>
                                ))}
                            </Menu.SubMenu>

                        

                        </Menu>
                        

                        <GeneralSearch className="" />
                    

                    {authUser && (
                        <div className="flex  justify-end space-x-3">
                            <div>
                                <Badge dot>
                                    <BellFilled/>
                                </Badge>
                            </div>

                            {authUser.profile_photo_path && (

                                <Dropdown overlay={userMenu} trigger={["hover"]} placement="bottomRight" className="mt-3">
                                    <Button type="link" icon={<DownOutlined />} className="user-dropdown">
                                        <Avatar src={<img width="40" className="rounded-full" alt={authUser.first_name}
                                                          src={`${DOCUMENT_BASE_URL}/${authUser.profile_photo_path}`}/>} />


                                        <span className="pl-1 text-black">{authUser.first_name}</span>
                                    </Button>
                                </Dropdown>
                            )}

                            {!authUser.profile_photo_path && (
                                <Dropdown overlay={userMenu} trigger={["hover"]} placement="bottomRight" className="mt-3">
                                    <Button type="link" icon={<DownOutlined />} className="user-dropdown">
                                        <Avatar src={<img width="40" className="rounded-full" alt="Phillips Outsourcing"
                                                          src="/images/app-icons/placeholder.jpg"/>} />


                                        <span className="pl-1 text-black">{authUser.first_name}</span>
                                    </Button>
                                </Dropdown>

                            )}



                        </div>)}

                    {!authUser && (
                        <div className="flex  justify-end space-x-20 mt-2 mr-24">
                            <div>

                                <Badge dot>
                                    <BellFilled/>
                                </Badge>
                            </div>
                            <Dropdown overlay={userMenu} trigger={["hover"]} placement="bottomRight" className="mt-3">
                                <Button type="link" icon={<DownOutlined />} className="user-dropdown">
                                    <Avatar src={<img width="40" className="rounded-full" alt="Phillips Outsourcing"
                                                      src="/images/app-icons/placeholder.jpg"/>} />


                                    <span className="pl-1 text-black">Welcome</span>
                                </Button>
                            </Dropdown>
                        </div>

                    )}
                    </div>
                </Header>
                <Content className="overflow-auto h-screen">
                    {authUser && (
                        <>
                            {(authUser.employee_status_id.toString() !== "1") ? (
                                <NoPageAccess/>
                            ) : (<App match={match}/>)}
                        </>
                    )}


                </Content>
                <Footer>
                    <div className="gx-layout-footer-content">
                        {footerText}
                    </div>
                </Footer>


        </Layout>
    )
};
export default MainApp;

